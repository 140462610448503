@font-face {
   font-family: 'ITC Avant Garde Gothic Std';
   src: local('ITC Avant Garde Gothic Std Bold'), local('ITCAvantGardeStd-Bold'),
       url('../fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'),
       url('../fonts/ITCAvantGardeStd-Bold.woff') format('woff');
   font-weight: bold;
   font-style: normal;
}



@font-face {
   font-family: 'ITC Avant Garde Gothic Std';
   src: local('ITC Avant Garde Gothic Std Book'), local('ITCAvantGardeStd-Bk'),
       url('ITCAvantGardeStd-Bk.woff2') format('woff2'),
       url('ITCAvantGardeStd-Bk.woff') format('woff');
   font-weight: normal;
   font-style: normal;
}

