


p,.p{
   font-family: $serif;
   font-size:$p-FS;
   line-height: $p-LH; 
   margin-top: 0px;
   color: $copy;
   // font-weight: 300;
   
   
   &:not(:last-child){
      margin-bottom: 20px;
   }
   &.light{
      font-weight: 300;
   }
   
}

h1,h2,h3,h4,h5,h6{
   margin-top: 0px;
   font-family: $sans;
   
}

.h1, h1{
   font-size: $h1-FS;
   line-height: $h1-LH;  
   color: $copy;
   margin-bottom: $v-pad-tiny;
   font-weight: bold;
   @media #{$tablet}{
      font-size: 2.2222222222em;
   }
   @media #{$phone}{
      font-size: 1.6666666667em;
   }
}

.h2, h2{
   font-size: $h2-FS;
   line-height: $h2-LH;  
   margin-bottom: 30px;
   color: $copy;
   font-weight: bold;
   @media #{$tablet}{
      font-size: 1.5555555556em;
   }
   @media #{$phone}{
      font-size: 1.444em;
   }
   
}

.h3, h3{
   font-size: $h3-FS;
   line-height: $h3-LH;  
   color: $blue;
   font-family: $serif;
   letter-spacing: -0.14px;
   margin-bottom: 20px;
}

a.text-link{
   font-weight: 900;
   // color: $midnight;
   font-size: $p-FS;
   &:hover{
      color:$orange;
   }
}

a.btn, button{
   background-color: $blue;
   padding: 15px 30px 14px;
   color: white;
   text-decoration: none;
   font-size: $p-FS;
   margin-top: $v-pad-tiny;
   display: inline-block;
   font-weight: bold;
   outline: none;
   appearance: none;
   cursor: pointer;
   border: 0;
   &:hover{
      background-color: darken($blue, 5%);
   }
   &.orange{
      background-color: $orange;
      &:hover{
         // background-color: darken($orange, 10%);
         background-color: $blue;
      }
   }
}
button::-moz-focus-inner,
input::-moz-focus-inner { // Inner padding and border oddities in FF3/4
  padding: 0;
  border: 0;
}
