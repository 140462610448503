footer{
   padding:$v-pad-medium 0px;
   background-color: $copy;
   @include pl-pr(1,1); 
   @include flex(flex, row, flex-start, space-between);
   a{
      font-size: $h3-FS;
      font-family: $sans;
      font-weight: bold;
      color: $orange;
      display: inline-block;
      float: none;
      &:hover{
         color: white;
      }
   }
   .left{
      h3{
         color: white;
         margin-bottom: 15px;
      }
      img{
         float: none;
         display: block;
         opacity: .5;
         margin-top: $v-pad-small;
      }
   }

   .right{
      img{
         height: 150px;
         margin-left: 15px;
      }
   }

   @media #{$phone}{
      @include flex(flex, column, flex-start, space-between);     
      .right{
         margin-top: $v-pad-small;
         img{
            height: 100px;
            margin-right: 15px;
            margin-left: 0px;
         }
      }
   }
}