
.page-header{
   background-color: $orange;
   padding: $v-pad-xlarge 0px;
   @include pl-pr(1,1);
   @include flex(flex, row, flex-start, space-between);
   overflow: hidden;
   // min-height: 80vh;
   box-sizing: border-box;
   position: relative;
   &:after{
      content:'';
      top: 0px;
      left: 0px;
      width: 200%;
      height: 80%;
      @include transform(rotate(-15deg) translate3D(-50%, -50%, 0));
      background-color: #FE6036;
      position: absolute;
      transform-origin: top left;
      z-index: 0;
      // border: 1000px solid fade-out($color: (white), $amount: .5)
   }
   &:before{
      content:'';
      bottom: 0px;
      left: 0px;
      width: 200%;
      height: 60%;
      @include transform(rotate(-15deg) translate3D(-25%, 120%, 0));
      background-color:#FF6E47;
      position: absolute;
      transform-origin: bottom left;
      z-index: 0;
      // border: 1000px solid fade-out($color: (white), $amount: .5)
   }
   .logo{
      position: absolute;
      top:$v-pad-small;
      z-index: 1;
      left: 0;
      @include pl(1,1);
      height: 30px;
   }
   .left,.right{
      position: relative;
      z-index: 1;
      @include width(5,6);
      h1, p{color: white;}
   }

   .right{
      @include width(4,5);
   }

   @media #{$phone}{
      @include flex(flex, column, flex-start, flex-start);
      padding-top: $v-pad-large;
      padding-bottom: $v-pad-medium;
      .left,
      .right{
         @include width(10,11);
         min-height: unset;
      }
   }
}





section.services{
   padding: $v-pad-large 0px;
   @include pl-pr(2,2);
   background-color: $lt-orange;
   h2{
      color: $blue;
      margin-bottom: 20px;
   }
   .top{
      border-bottom: 1px solid #DFD1CA;
      padding-bottom: $v-pad-small;
      margin-bottom: $v-pad-small;
   }
   .text{
      column-count: 2;
      column-gap: $gutter;
   }
   .service-table{
      h2{
         text-align: center;
         padding: 20px 0px;
      }
      table{
         width: 100%;

         overflow: scroll;
         
         thead{
            th{
               font-size: 12px;
               padding-bottom: 10px;
            }
         }
         tbody {
            
            box-shadow: 0 12px 24px 0 rgba(0,0,0,0.09);
            td{
               border-bottom: 1px solid #979797;
               border-right: 1px solid #979797;
               padding: 15px 15px;
               font-family: $sans;
               color: $copy;
               font-size: 14px;
               font-weight: 500;
               line-height: 17px;
               
               &:first-child{
                  border-left: 1px solid #979797;
               }
               &.check{
                  background-color: white;
               }
            }
            tr:first-child{
               td{

                  border-top: 1px solid #979797;
                  &:first-child{
                     border-radius: 4px 0 0 0 ;
                  }
                  &:last-child{
                     border-radius: 0 4px 0 0 ;
                  }
               }
            }
            tr:last-child{
               td:first-child{border-radius:  0 0 0 4px;}
               td:last-child{border-radius: 0  0 4px 0 ;}
            }
         }
      }

   }
   // .service-list{
   //    @include flex(flex, row, flex-start, space-between);
   //    flex-wrap: wrap;
   //    .group{
   //       @include width(4,4);
   //       margin-bottom: $v-pad-medium;
   //    }
      
   //    li{
   //       @extend p;
   //       &:not(:last-child){
   //          margin-bottom: 2px;
   //       }
   //       &:before{
   //          content: url('../images/list.svg');
   //          @include transform(translateX(-200%));
   //          position: absolute;
   //       }
   //    }

   // }
   @media #{$phone}{
      padding: $v-pad-medium 0px;
      @include pl-pr(1,1);
      .text{
         column-count: 1;
      }
      // .service-list{
      //    @include pl-pr(1,1);
      //    @include flex(flex, column, flex-start, flex-start);
      //    .group{
      //       width: 100%;
      //       margin-bottom: $v-pad-tiny;
      //    }
      // }
      .service-table{
         // @include mr(-1,-1);
         // @include ml(-1,-1);
         // @include pr(1,1);
         table{
            display: block;
            overflow-x: auto;
            // white-space: nowrap;
            tbody{
               td:first-child{
                  min-width: 200px;
               }
            }
         }
      }
   }
}




section.tools{
   background-color: $blue;
   padding: $v-pad-medium;
   text-align: center;
   h5{
      opacity: 0.5;
      font-family:$serif;
      font-size: 23px;
      color: #E5E8F8;
      letter-spacing: -0.14px;
   }
   .logos{
      @include flex(flex, row, center, center);
      img{
         margin: 0px 20px;
         max-height: 30px;
         max-width: 100px;
      }
   }

   @media #{$phone}{
      padding: $v-pad-medium 0px;
      .logos{
         @include flex(flex, row, center, flex-start);
         overflow: scroll;
         padding-bottom: $v-pad-small;
         @include ml-mr(1,1);
         img{
            margin: 0px 20px;
            height: 30px;
            max-width: unset;
         }
      }
   }

}


section.about{
   background-color: $lt-blue;
   padding-top: $v-pad-large;
   padding-bottom: $v-pad-large;
   .card{
      position: relative;
      background: white;
      @include ml-mr(1,1);
      @include width(10,11);
      // max-height: 80vh;
      // overflow: hidden;
      @include flex(flex, row, flex-start, space-between);
      @include pl-pr(.5,1);
      padding-top: 80px;
      padding-bottom: 80px;
      box-sizing: border-box;
      // &:after{
      //    content:'';
      //    bottom: 0px;
      //    left: 0px;
      //    @include width(6,6);
      //    height: 80px;
      //    position: absolute;
      //    z-index: 30;
      //    @include vertical-gradient(rgba(255,255,255,0),rgba(255,255,255,1),  0%, 100%);
      // }

   }
   .left, .right{
      box-sizing: border-box;
      // height: 80vh;
      
   }
   .left{
      // overflow: scroll;
      width: 100%;
      position: relative;
      // columns: 2;
      // column-gap: $column + $gutter;
      // *:last-child{
      //    padding-bottom: 80px;
      // }
      // &:after{
      //    content: '';
      //    @include width(4.5,5);
      // }
      
      h1,h2,h3{
         color: $orange;
      }

   }
   .right{
      width: 70%;
      margin-left: 10%;
      position: sticky;
      top: $v-pad-small;
      img{
         height: 100%;
         width: 100%;
         object-fit: cover;
         object-position: center top;

      }
   }
   @media #{$phone}{
      padding-top: $v-pad-small;
      padding-bottom: $v-pad-small;
      .card{
         @include ml-mr(0,1);
         @include width(12,11);
         @include flex(flex, column-reverse, flex-start, flex-start);
         max-height: unset;
         padding-top: $v-pad-small;
         padding-bottom: $v-pad-small;
         &:after{
            content:'';
            display: none;
            height: 0px;
         }
      }
      .right,
      .left{
         width: 100%;
         height: unset;
      }

      .right{
         height: 400px;
         margin: 0px 0px $v-pad-small;
         position: relative;
         img{
            object-position: top center;
         }
      }
   }
}



#secretHoneypotParamName{

   display: none;
   visibility: hidden;
}

section.contact{
   padding-bottom: $v-pad-large;
   background-color: $lt-blue;
   .content{
      @include width(8,9);
      @include ml-mr(2,2);
   }
   h2 { color: $blue;}
   form{
      // @include flex(flex, row, flex-start, space-between);
      column-count: 2;
      column-gap: $gutter;
      max-height: 240px;
      // flex-wrap: wrap;
      .error h3{
         color: red;
      }
      .input-group{
         margin-bottom: 20px;
         @include width(4,4);
         position: relative;
      }
      input, textarea{
         outline: 0px;
         appearance: none;
         border:0px;
         -webkit-appearance: none;
         -moz-appearance:    none;
         padding: 20px 0px 10px;
         box-shadow: none;
         border-bottom: 2px solid $copy;
         width:100%;
         box-sizing: border-box;
         background-color: transparent;
         background: transparent;
         resize: none;
         // &:invalid{
         //    border-bottom-color: red;
         // }
         &:focus{
            border-bottom-color: $blue;
            // background-color: darken($color: $lt-blue, $amount: 3%);
            // background: darken($color: $lt-blue, $amount: 3%);
         }
         &:focus + label,
         &:focus ~ label,
         &:not([value=""]) + label{
            font-size: 12px;
            top: -10px;
         }
      }
      textarea{
         position: relative;
         // height: ($p-LH * $p-FS )*6;
         height: 199px;
      }
      label{
         pointer-events: none;
         width: 100%;
         font-size: 16px;
         color: fade-out($color: $copy, $amount: .5);
         position: absolute;
         left: 0;
         top: 20px;

      }
      
   }
   .btn-wrapper{
      text-align: center;
      margin: 20px auto;
      
   }

   @media #{$phone}{
      padding-bottom: $v-pad-small;
      
      .content{
         @include width(10,11);
         @include ml-mr(1,1);
      }
      form{
         column-count: 1;
         max-height: unset;
         .input-group{
            width: 100%;
         }
      }
   }
}