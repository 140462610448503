

// ---------------------------------------------
// Fonts
// ---------------------------------------------
$serif   : 'poynter-oldstyle-display', 'Times New Roman', Times, serif;
$sans    : 'ITC Avant Garde Gothic Std', Helvetica,Arial,sans-serif;

$base-FS   : 18px;


$p-FS      : 1em;
$p-LH      : 1.7777777778;

$h1-FS     : 2.77777777em;
$h1-LH     : 1.25;

$h2-FS     : 2.11em;
$h2-LH     : 1.25;

$h3-FS     : 1.2777777778em;
$h3-LH     : 1.4;



// ---------------------------------------------
// Colors
// ---------------------------------------------

$blue     : #2B50D6;
$lt-blue  : #F1F3FB;
$orange   : #FF673E;
$lt-orange: #FBEBE1;
$copy     : #211E2E;

// ---------------------------------------------
// Vertical Rythym
// ---------------------------------------------
// $vertical-padding-section: 10vw;
$v-pad-xlarge            : 200px;
$v-pad-large             : 150px;
$v-pad-medium            : 50px;
$v-pad-small             : 40px;
$v-pad-tiny              : 20px;
// ---------------------------------------------
// Animations
// ---------------------------------------------

$outQuad 	               : cubic-bezier(0.165, 0.840, 0.190, 0.950);
$easeInQuad                : cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuad               : cubic-bezier(0.165, 0.84, 0.44, 1);
$outCubic                  : cubic-bezier(0.215, 0.61, 0.355, 1);

$z-index:(
   'base': 0,
   'one': 1,

   
)