//===============================================================/
//                   For Development ONLY
//===============================================================/

.gridMe{

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;
	pointer-events:none;
	&.hidden{
		display: none;
	}

	div{
		position: absolute;
		height: 100%;
		width: $column;
		position: absolute;
		background-color: rgba(pink, .3);
		border-left:1px solid rgba(pink,1);
		border-right:1px solid rgba(pink,1);
		pointer-events:none;
        // @include box-sizing;

        @for $nth from 1 through $columnCount{
        	&:nth-child(#{$nth}){
        		left: ($column * ($nth - 1)) + ($gutter * ($nth));
        	}
        }

     }

  }

